import { Link } from "gatsby"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    css={css`
      background-color: #1a645c;
    `}
  >
    <div
      css={css`
        margin: 0 auto;
        max-width: 1280px;
        padding: 3rem 1.0875rem 0 0;
      `}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          css={css`
            color: #fff;
            text-decoration: none;
            position: relative;
            top: 0.4rem;
          `}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
